import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/order",
        name: "order",
        meta: { title: "订单" },
        redirect: "/visa",
        component: Main,
        children: [
			{
			    path: '/visa',
			    name: 'visa_order',
			    meta: {
			        title: "签证订单",
			        icon: "icon_fenxiao_order",
			        parentPath: "/order",
			        permission: ["view"],
			    },
			    component: () => import('@/views/visa/lists.vue'),
			},
			{
			    path: '/visa/add',
			    name: 'visa_order_add',
			    meta: {
			        hidden: true,
			        title: '新增订单',
					parentPath: "/order",
			        prevPath: '/visa',
			    },
			    component: () => import('@/views/visa/add.vue')
			},
			{
			    path: '/visa/addUserOrder',
			    name: 'visa_order_addUserOrder',
			    meta: {
			        hidden: true,
			        title: '新增用户订单',
					parentPath: "/order",
			        prevPath: '/visa',
			    },
			    component: () => import('@/views/visa/addUserOrder.vue')
			},
			{
			    path: '/visa/edit',
			    name: 'visa_order_edit',
			    meta: {
			        hidden: true,
			        title: '编辑',
			        parentPath: '/order',
			        prevPath: '/visa'
			    },
			    component: () => import('@/views/visa/edit.vue')
			},
			{
			    path: '/visa/payment',
			    name: 'visa_order_payment',
			    meta: {
			        hidden: true,
			        title: '缴费',
			        parentPath: '/order',
			        prevPath: '/visa'
			    },
			    component: () => import('@/views/visa/paymentOrder.vue')
			},
            {
                path: "/order/renew/lists",
                name: "renew_order",
                meta: {
                    title: "续费订单",
                    icon: "icon_fenxiao_order",
                    parentPath: "/order",
                    permission: ["view"],
                },
                component: () => import("@/views/order/renew/lists.vue"),
            },
            {
                path: "/order/renew/detail",
                name: "renew_order_detail",
                meta: {
                    hidden: true,
                    title: "续费订单",
                    parentPath: "/order",
                    prevPath: "/order/renew/lists"
                },
                component: () => import("@/views/order/renew/detail.vue"),
            }
        ],
    },
];
export default routes;
